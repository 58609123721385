

body{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-image: linear-gradient(to left, rgb(154, 160, 172) , rgb(211, 211, 212));
  overflow: hidden;
}

:root{
  --border-color:rgba(97, 97, 97, 0.568);
  --button-color:rgb(255, 255, 255);
  --background-circle:rgba(160, 160, 160, 0.37);
  --visual-info:rgb(221, 221, 221);
  --visual-options:rgb(221, 221, 221);
}


/* Navigation */
.navbar{
  position: absolute;
  top: 20px;
  width: 260px;
  margin-left: auto;
  margin-right: 100px;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  background-color: var(--button-color);
  border-radius: 5px;
  box-shadow: 0px 0px 2px 2px #d3d3d3;
}

.navbar button{
  background-color: rgba(155, 155, 155, 0);
  color: #141414;
  border-radius: 5px;
  height: 50px;
  border: none;
  padding: 0px;
  transition: 200ms background-color;
  font-size: 15px;
  border-left: 2px solid rgb(77, 77, 77);
}

.navbar button:hover{
  background-color: rgb(100, 100, 100);
  color: rgba(255, 255, 255, 0.705);
  cursor: pointer;
}

/* BackGround */
.backGround{
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.logo{
  position: fixed;
  width: 300px;
  left: 10px;
  top: -40px;
  font-size: 50px;
  font-weight: 900;
  color: #8b8b8b
}


.backGroundCircle1{
  position: absolute;
  height: 50vw;
  width: 50vw;
  border-radius: 50%;
  background:  var(--background-circle);
  bottom: -5vw;
  right: -10vw;
  z-index: -1;
}

.backGroundCircle2{
  position: absolute;
  height:600px;
  width: 600px;
  border-radius: 50%;
  background:  var(--background-circle);
  top: -500px;
  left: -260px;
  z-index: -1;
}

.backGroundCircle3{
  position: absolute;
  height: 15vw;
  width: 15vw;
  border-radius: 50%;
  background: var(--background-circle);
  bottom: 0vw;
  left: 15vw;
  z-index: -1;
}

/* Footer */
.footer{
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100vw;
  height: 40px;
  display: grid;
  grid-template-columns: 1fr 60px 1fr;
  background-color: rgba(71, 71, 71, 0.301);
  border-top: 2px solid rgba(70, 70, 70, 0.678);
  /* box-shadow: 0px 0px 1px 1px rgba(97, 97, 97, 0.87); */
}

.contact{
  margin: auto;
  padding-top: 2px;
}

.copyright{
  margin-left: auto;
  margin-right: 50px;
}

.copyright p{
  margin: auto;
  padding: 0px;
  padding-top: 9px;
  font-size:17px;
  color: rgb(34, 34, 34);
}

.cspan{
  color: rgb(206, 206, 206);
}

/* Main Section */
.mainSection{
  margin: auto; 
  margin-top: 70px;
  height: 804px;
  display: grid;
  grid-template-columns: 1fr 600px 1fr;
  overflow: hidden;
}

/* Neural Network */
.neuralNetwork{
  position: relative;
  height:150px;
  width: 600px;
  display: grid;
  grid-template-rows: 150px 0px 0px 1fr;
  border: 2px solid var(--border-color);
  border-radius: 10px;
  box-shadow: 0px 0px 1px 1px rgba(97, 97, 97, 0.425);
  background-color: rgba(255, 255, 255, 0.3);
}

.manualPlay{
  position: absolute;
  visibility: visible;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: 2px solid var(--border-color);
  border-radius: 10px;
  box-shadow: 0px 0px 1px 1px rgba(97, 97, 97, 0.425);
  background-color: rgba(255, 255, 255, 0.3);
}

/* Options */
.options{
  width: 600px;
  display: grid;
  grid-template-columns: 1fr 200px 0px;
  border-radius: 10px 10px 10px 10px;
  transition: 500ms margin-top;
}

.optionHeader{
  display: grid;
  grid-template-columns: 40px 1fr ;
  font-size: 14px;
  margin-top: -12px;
}

.optionsButton{
  height:50px;
  background-color: rgba(255, 255, 255, 0);
  border: none;
}

.optionImage{
  position: relative;
  width: 30px;
  height: 30px;
  z-index: 2;
}

.optionImage:hover{
  cursor: pointer;
}

.setupInputs{
  margin-top: 5px;
  height: 130px;
  display: grid;
  grid-template-rows: 60px 1fr 1fr;
  z-index: 6;
}

.setupInput{
  display: grid;
  grid-template-columns: 1fr 50px;
}

.setupInputSlider{
  height:2px;
  width:110px;
}

.isetup{
  height: 20px;
  display: grid;
  grid-template-columns: 40px 1fr 40px;
}

.isetup p{
  margin: 0px;
  padding: 0px;
  padding-top: 3px;
}

.isetupValue {
  font-weight: 900;
}

.setupInput h2{
  font-size: 30px;
  margin: 0px;
  padding:0px;
}

.additionaOptions{
  transition: 300ms width;
  transition-timing-function: ease-out;
  position: relative;
  margin-top: 5px;
  height: 744px;
  /* display: grid; */
  /* grid-template-rows: 40px repeat(10,45px); */
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.3);
  border: 2px solid var(--border-color);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  max-width: 425px;
}

.aOptionHeader{
  font-size: 20px;
}

.aOptionContainer  h2{
  margin:0px;
  margin-bottom: 8px;
  padding: 0px;
}

.attributes{
  margin: 0px;
  padding: 0px;
}

.inputContainer{
  width: 400px;
  margin-left:40px; 
}

.input{
  display: grid;
  grid-template-columns: 270px 70px 40px;
  grid-template-rows: 23px 30px;
}

.input p{
  margin: 0px;
  padding: 0px;
}

.inputValue{
  margin: 0px;
  margin-top: 12px;
  text-align: left;
}

.description{
  margin-top: 0x;
  width: 40px;
  height: 40px;

}

.description:hover .descriptionText{ 
  width: 360px;
  padding-left: 20px;
  padding-right: 20px;
}

.descriptionText{  
  transition: 200ms width;
  position: relative;
  left: -400px;
  width: 0px;
  height: 50px;
  overflow: hidden; 
  background-color: rgb(129, 129, 129);
  color: rgb(223, 223, 223);
  z-index: 10;
  border-radius:5px;
}

.infosvg{
  fill: rgb(150, 71, 71);
}

.inputSlider{
  height: 2px;
  width: 250px;
}

/* Simulation control */
.simulationControl{
  height: 140px;
  display: grid;
  grid-template-rows: 40px 1fr;
}

.simulationButtons{
  height: 35px;
  display: grid;
  grid-template-columns: repeat(3,120px);
  grid-gap: 5px;
  margin: 0px 5px;
}

.simulationButtons button{
  border: none;
  font-size: 15px;
  background-color:  var(--button-color);
  color: #141414;
  border-radius: 5px;
  border-left: 2px solid rgb(77, 77, 77);
  user-select: none;
}

.simulationButtons button:hover{
  background-color: rgb(100, 100, 100);
  color: rgba(255, 255, 255, 0.705);
  cursor: pointer;
}

.simulationState{
  padding-left: 10px;
  height: 80px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.795) 39%, rgba(0,0,0,0) 100%);
}

.manualState{
  width: 70%;
}

.simulationState h3{
  margin: 0px;
  margin-top: 7px;
  padding: 0px;
  user-select: none;
}

.simulationState h1{
  margin: 0px;
  padding: 0px;
  user-select: none;
}

.statistics h3{
  text-align: center;
  margin: 0px;
  margin-top: 30px;
}
.statistics h1{
  margin: 0px;
  padding: 0px;
  text-align: center;
}

/* Simulation stats */
.simulationStats{
  position: relative;
  height: 150px;
  border-top-right-radius: 5px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  color: rgb(114, 114, 114);
  border-left: 2px solid rgb(131, 131, 131);
  padding-left: 10px;
  background-color: #bbbbbb;
}

.simulationStats p{
  font-size: 18px;
  padding-top: 5px;
  margin: 0px;
}

.simulationNumber{
  font-size: 25px;
  margin: 0px;
  font-weight: 600;
}

/* Speed Input */
.speedInput{
  position: relative;
  margin-left: 3px;
  font-size: 18px;
  margin-top: -17px;
}

.speed{
  height: 4px;
  position: relative;
  width: 594px;
  border: 30px;
  margin: 0px;
  padding: 0px;
  font-size: 25px;
  z-index: 6;
}

.speedTag{
  margin: 0px;
  padding: 0px;
}

.speedHeader{
  font-size: 25px;
}

.canvas{
  background-color: rgb(240, 240, 240);
  border-bottom: 2px solid var(--line-color);
  border-top: 2px solid var(--line-color);
  border-radius: 5px;
  border: 2px solid rgb(43, 43, 43);
  width: 599px;
  visibility: visible;
}

.statCanvas{
  position: relative;
  margin-left: auto;
  margin-top: -150px;
  background-color: rgba(255, 255, 255, 0);
  border-top-right-radius: 7px;
  z-index: 3;
}

.chartContainer{
  overflow: hidden;
  height: 800px;
  width: 600px; 
}

.charts{
  width: 500px; 
  margin:auto;
  margin-top: 170px;
  display: grid;
  grid-row-gap: 50px;
  transition: 600ms margin-left;
}

.chart{
  background-color: rgba(255, 255, 255, 0.555);
  border-radius: 5px;
}

.visuals{  
  height: 300px;
  width: 500px;
  margin:auto;
  transition: 500ms margin-top;
}

.visualNavigation{
  margin-top: 50px;
  height: 50px;
  background-color:  var(--button-color);
  display: grid;
  grid-template-columns: repeat(4,1fr);
  border-radius: 5px;
  box-shadow: 0px 0px 2px 2px #d3d3d3;
  transition: 500ms margin-top;
}

.visualNavigation button{
  background-color: rgba(141, 32, 32, 0);
  color: #141414;
  border-radius: 5px;
  height: 50px;
  border: none;
  padding: 0px;
  transition: 200ms background-color;
  font-size: 15px;
  border-left: 2px solid rgb(77, 77, 77);
}

.visualNavigation button:hover{
  background-color: rgb(100, 100, 100);
  color: rgba(255, 255, 255, 0.705);
  cursor: pointer;
}

.visualHeader{
  width: 100%;
  height: 50px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  border-left: 2px solid rgb(77, 77, 77);
  display: grid;
  grid-template-columns: 1fr 50px;
}

.visualHeader button{
  background-color: transparent;
  border: none;
}

.visualHeader h1{
  margin: 0px;
  padding: 0px;
  padding-left: 5px;
}

/* Visual Info */
.visualInfo{
  position: relative;
  margin-top: -5px;
  width: 494px;
  margin-left: 2px;
  height: 38px;
  background-color: var(--visual-info);
  z-index: -1;
  display: flex;
  border: 2px solid rgb(105, 105, 105);
  border-top: 0px;
  border-radius: 0px 0px 5px 5px;
  display: grid;
  grid-template-columns: auto 1fr;
}

.visualInfo p{
  margin: 0px;
  padding-left: 5px;
  align-self: center;
}

.visualMessage{
  color: rgb(148, 0, 0);
  margin-left: auto;
}

.message{
  color: rgb(148, 0, 0);
}

/* Visual Info */
.familyVisualInfo{
  display: flex;
}

.birdView{
  margin-top: 50px;
}

.birdInput p{
  text-align: center;
  margin: 0px;
  padding: 0px;
  font-size: 10px;
  z-index: -2;
}

.birdInput{
  display: grid;
  grid-template-columns: repeat(6,1fr);
}

.fade{
  position: relative;
  margin-top: -200px;
  height: 200px;
  z-index: -1;
}

.neuralNet{
  margin-top: 50px;
  display: grid;
  grid-template-rows: 50px auto 45px 0px 1fr;
  overflow: hidden;
}

.layerOptions {
  width: 494px;
  margin-left: 2px;
  height: 0px;
  border: 2px solid rgb(105, 105, 105);
  border-top: 0px;
  border-bottom: 0px;
  background-color: var(--visual-options);
}

.layerOpen{
  margin-left: -40px;
}

.lOptions{
  height: 40px;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  
}
.lOptions h3{
  background-color: rgb(187, 187, 187);
  text-align: center;
  margin: 5px;
  padding-top: 3px;
  font-size: 18px;
  font-weight: 500;
  color: rgb(82, 82, 82);
  border-radius: 5px;
}

.oValue{
  color: rgb(55, 61, 80);
}

.neuralOptions{
  display: grid;
  grid-template-columns: repeat(3,1fr);
}

#layers{
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(70px,1fr));
  height: auto;
}

.neuralSvg{
  position: relative;
  height: 2000px;
  width: 500px;
  z-index: -2;
}

.neuralLine{
  stroke-dasharray: 1500;
  stroke-dashoffset: 0;
  animation-name: example;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes example {
  0%   {stroke-dashoffset: 1500}
  100% {stroke-dashoffset: 0}
}

.layer{
  display: grid;
  grid-template-rows: 200px repeat(auto-fit,minmax(30,1fr)); 
  justify-self: center;
  grid-row-gap: 10px;
  color: #7c7c7c;
}

.layerHeader h3{
  margin: 0px;
  padding: 0px;
}

.addLayer{
  position: relative;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  padding: 0px 9px 0px 9px;
  top: 39px;
}

.addLayer button{
  height: 30px;
  width: 30px;
  font-size: 15px;
  border-radius: 50%;
  border: 1px solid rgb(194, 194, 194);
  background-color: rgb(243, 243, 243);
  box-shadow: inset 0 0 3px rgb(97, 97, 97);
}

.addLayer button:hover{
  cursor: pointer;
  background-color: rgb(233, 178, 178);
}

.minus{
  padding-bottom: 5px;
}
.plus{
  padding-bottom: 1px;
}

.neuron{
  height: 30px;
  width: 30px;
  border: 1px solid rgb(194, 194, 194);
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  justify-self: center;
  box-shadow: inset 0 0 3px rgb(97, 97, 97);
  transition: 200ms transform;
}

.neuron:hover{
  transform: scale(0.95)
}

.neuronInfo{
  position: relative;
  top: 0px;
  left: 22px;
  height: 0px;
  width: 0px;
  background-color: #979797;
  color: rgb(230, 230, 230);
  opacity: 0;
  transition: opacity 200ms, height 200ms, width 200ms, top 200ms,opacity 200ms;  
  border: 2px solid rgb(194, 194, 194);
  border-radius: 5px 5px 5px 0px;
  overflow: hidden;
  text-align: center;
}

.neuron:hover .neuronInfo{
  opacity: 1;
  width: 70px;
  top: -25px;
  height: 25px;
}

.DNA{
  margin-top: 50px;
}

.dnaPage{
  width: 500px;
  height: 500px;
  margin-top: 20px;
  height: auto;
  display: grid;
  grid-template-rows: 0px 1fr;
}

.centerAlign{
  height: 1px;
  width: 1px;
  justify-self: center;
  margin-top: 50%;
}

.centerDot{
  position: relative;
  top: -15px;
  left: -15px;
  height: 30px;
  width: 120px;
  border: 2px solid rgba(53, 53, 53, 0.638);
  background-color: rgb(163, 163, 163);
  border-radius: 25px;
  z-index: 1;
  display: flex;
}

.centerDot p{
  padding-left: 5px;
  font-size: 15px;
  margin: 0px;
  align-self: center;
}

.fitnessPercentage{
  align-self: center;
  margin: 0px;
  padding-left: 5px;
  font-size: 17px;
}

/* Family Tree */
.familyTree{
  margin-top: 50px;
  max-height: 600px;
  display: grid;
  grid-template-rows:50px 1fr 35px;
}

.generationContainer{
  max-height: 500px;
  overflow-y: scroll;
  display: flex; 
  flex-direction: column-reverse;
  width: 494px;
  margin-left: 5px;
  scrollbar-width: none;
}

.generationContainer::-webkit-scrollbar {
  display: none;
}

.generationOptions{
  width: 494px;
  margin-left: 2px;
  height: 0px;
  border: 2px solid rgb(105, 105, 105);
  border-top: 0px;
  border-bottom: 0px;
  background-color: var(--visual-options);
  overflow: hidden;
}

.generation{
  display: grid;
  grid-template-columns: 45px 1fr;
}

.genHeader{
  display: flex;
  justify-content: center;
  margin: 0px;
  font-size: 13px;
  color: #000000;
  background-color: rgb(134, 134, 134);
  border-right: 2px solid rgb(102, 102, 102);
  border-top: 2px solid rgb(102, 102, 102);
  font-weight: 500;
}

.generationBirds{
  display: grid;
  width: auto;
  grid-template-columns:repeat(auto-fill,minmax(44px,44px));
}

.oldgen{
  background-color: rgb(134, 180, 137);
  height: 1fr;
  padding-top: 2px;
  min-width: 44px;
}

.oldgen p{
  text-align: center;
  margin: 0px;
  font-size: 12px;
  padding-bottom: 3px;
}

.currentGen{
  display: grid;
  grid-template-columns: repeat(12,1fr);
  border-top: 2px solid black;
  grid-gap: 2px;
  width: 494px;
  margin-left: 5px;
}

.currentGenHeader{
  grid-column: 1/3;
  border:0px;
  padding-top: 5px;
}

.currentGen p{
  background-color: rgb(134, 134, 134); 
  color: #ffffff;
  text-align: center;
  font-size: 15px;
  margin: 0px;
  padding: 0px;
  padding-top: 5px;
  height: 30px;
}

.currentGen p:hover{
  background-color: rgb(170, 170, 170);
  cursor: pointer;
}

.generationOptions{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.genInfo{
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: rgb(199, 199, 199);
  margin: 5px;
  margin-right: 0px;
  border-radius: 5px;
}

.genInfo h2{
  margin: 0px;
  padding-left: 5px;
}

.genInfo p{
  grid-column: 1/3;
}

.genSettings{
  background-color: rgb(199, 199, 199);
  margin: 5px;
  border-radius: 5px;
}

.genSettingsValues{
  display: grid;
  grid-template-columns: repeat(3,1fr);
}

.genInfo div{
  height: 15px;
  width: 70px;
  font-size: 12px;
  padding-bottom: 2px;
  border-radius: 3px;
  text-align: center;
  justify-self: center;
}

.divParents{
  margin: 5px;
  margin-top: 10px;
  height: 15px;
  font-size: 14px;
  padding: 0px 5px 6px 5px;
  border-radius: 2px;
}

.parentOne{
  background-color: white;
}
.parentTwo{
  background-color: gray;
}

.checkBoxInput{
  display: grid;
  grid-template-columns: 35px 150px;
}

.selectorButton{
  background: lightgray;
  border: 1px solid white;
  margin: 4px;
}

.selectorButton:hover{
  cursor: pointer;
}

.selectorButton p{
  margin: 0px;
  padding: 0px;
  font-size: 15px;
  text-align: center;
}

.checkBoxInput p{
  margin: 0px;
  padding: 0px;
  font-size: 20px;
}

.checkBox{
  height: 25px;
  width: 25px;
  background-color: #515151;
}

input[type="checkbox"] {
  cursor: pointer;
  appearance: none;
  outline: 0;
  background: lightgray;
  border: 1px solid white;
}

input[type="checkbox"]:checked {
  background: #a8a8a8;
}

input[type="checkbox"]:after {
  content: '';
  position: relative;
  left: 33%;
  top: 0%;
  width: 25%;
  height: 70%;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

input[type="checkbox"]:checked:after {
  display: block;
}

/* Chrome */
@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type='range'] {
    -webkit-appearance: none;
    background-color: rgb(68, 173, 138)
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    width: 30px;
    height: 2px;
    border: none;
    border-radius: 3px
  }
  
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 14px;
    width: 14px;
    border-radius: 510%;
    background: #141414;
    border: 2px solid #515151;
    margin-top: -6px;
    cursor: pointer
  }

  input[type=range]:focus {
    outline: none
  }
}

@media only screen and (max-width: 1050px) {
  
  .footer{
    display: none;
  }

  .backGroundCircle1{
    display: none;
  }
  
  /* BackGround */
  .backGroundCircle2{
    display: none;
  }
  
  /* BackGround */
  .backGroundCircle3{
    display: none;
  }
  
  .navbar{
    margin: auto;
    max-width: 99vw;
    z-index: 10;
    top: 0px;
    height: 30px;
  }

  .navbar button{
    height: 30px;
  }

  .logo{
    width: 100%;    
    display: flex;
    margin: auto;
    left: 0px;
    z-index: -1;
  }

  .mainLogo{
    position: relative;
    margin: auto;
    margin-top: 40px;
  }

  input[type='range']::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    margin-top: -8px;
  }

  .mainSection{
    max-height: 100vh;
    margin-top: 0px;
    grid-template-columns: 1vw;
    grid-template-rows: repeat(3,0px);
    overflow: hidden;
  }
  .neuralNetwork{
    margin-top: 30px;
    width: 100vw;
    height: 80vh;
    grid-template-rows: 150px 0px 0px 600px 50px;
    border-radius: 0px;
    border: 0px;
    background-color: rgb(173, 173, 173);
    height: auto;
    /* z-index: 3; */
  }


  .manualPlay{
    height: 100px;
    margin-top: 100px;
    background-color: transparent;
    border: none;
    box-shadow: 0px 0px 1px 1px rgba(97, 97, 97, 0);
  }

  .manualState{
    display: none;
  }


  /* Options */
  .options{
    width: 98vw;
    grid-template-columns: minmax(120px,1fr) 1fr;
  }

  .setupInputs{
    width: auto;
  }

  .setupInput{
    width: auto;
  }

 .setupInputSlider{
   width: 100%;
 }
  .isetup{
    width: auto;
    grid-template-columns: 40px 1fr 40px;
    text-align: center;
  }

  .simulationControl{
    margin-top: 60px;
    height: 120px;
    display: grid;
    grid-template-rows: 40px 1fr;
  }
  .simulationButtons{
    margin-top: 10px;
    position: absolute;
    grid-template-columns: repeat(auto-fill,minmax(100px,1fr));
    grid-auto-rows: minmax(25px,50px);
    grid-row: 2/3;
    margin-top: -65px;
  }

  .simulationState{
    margin-top: -60px;
    grid-row: 1/2;
  }

  .additionaOptions{
    max-width: 100vw;
    position: absolute;
    top: 136px;
    border: 0px;
    box-shadow: 0px 0px 1px 1px #d3d3d300;
    min-height: 100vh;
    z-index: 5;
    overflow: scroll;
    height: auto;
  }

  .inputContainer{
    position: absolute;
    width: 100vw;
    margin-left:0px; 
  }
  .input{
    grid-template-columns: 1fr 70px 50px;
    grid-template-rows: 25px 30px;
  }
  .inputSlider{
    width: 90%;
    justify-self: center;
    padding-left: 10px;
  }
  .input p{
    padding-left: 10px;
  }

  .descriptionText{  
    left: -100vw;
    max-width: 70vw;
    padding-left: 80px;
    height: auto;
    min-height: 50px;
  }

  .description:hover .descriptionText{ 
    padding-left: 60px;
    padding-right: 60px;
  }

  .speedInput{
    width: 0px;
    transition: 0ms width;
  }

  .description:hover .descriptionText{ 
    width: 98vw;
  }

  .canvas{
    width: 99vw;
    height:75vh;
  }
  .statCanvas{
    z-index: 1;
  }
  
  .simulationStats{
    background-color: #bbbbbb;
  }
  
  .speed{
    width: 98vw;
    z-index: 4;
  }

  .visualNavigation{
    visibility: visible;
    position: fixed;
    z-index: 10;
    bottom: 0px;
    width: 100vw;
    border-radius: 0px;
  }

  .visualNavigation{
    grid-template-columns: repeat(auto-fit,minmax(40px,1fr));
    height: 30px;
  }

  .visualHeader{
    /* visibility: hidden; */
    width: 100vw;
    /* height: 50px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border-left: 2px solid rgb(77, 77, 77);
    display: grid;
    grid-template-columns: 1fr 50px; */
  }
  
  .visualNavigation button{
    height: 30px;
    font-size: 13px;
    border-left: 2px solid rgb(77, 77, 77);
    /* border-left: 0px; */
    margin: 0px;
  }

  .visuals{
    width: 100vw;
    height: 80vh;
    margin-top: 190px;
  }

  .visualPages{
    margin-top: -60px;
    height: 100vh;
  }

  .visualInfo{
    display: none;
  }

  .visualShade{
    display: none;
    position: absolute;
    height: 180px;
    width: 100vw;
    /* background-color: rgb(190, 199, 206); */
    z-index:-1;
  }

  .optionImage{
    z-index: 2;
  }

  .layerOptions {
    width: 100vw;
  }

  .lOptions h3{
    font-size: 15px;
  }

  .layerOptions{
    z-index: 2;
  }

  .layer{
    display: grid;
    grid-template-rows: 500px repeat(auto-fit,minmax(15,1fr)); 
    justify-self: center;
    grid-row-gap: 5px;
    color: #7c7c7c;
  }

  #layers{
    width: 100vw;
  }

  .neuralNet{
    margin: auto;
    height: 600px;
    /* width: 200px; */
    margin-top: 50px;
  }

  .neuralSvg{
    margin-top: 50px;
    z-index: -1;
  }

  .addLayer{
    margin-top: 20px;
  }
  .chartContainer{
    /* overflow-y: scroll; */
    width: 99vw; 
    margin-left: -10px;
    height: 450px;
    z-index: 5;
    margin-top: 170px;
  }
  .charts{
    margin-top: 30px;
    width: 90vw;
    height: 400px;
    grid-row-gap: 0px;
    z-index: 5;
    
  }  
  .chart{
    height:200px;
    width: 85vw;
  }

  .dnaPage{
    width: 99vw;
    height: 99vw;
  }
  
  .birdInput p{
    z-index: -1;
  }
}